import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Energy",
  "date": "2019-12-16T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`"If you want to know the secret of universe; Think in terms of Energy, Frequency, Vibration".
I see everything as Energy, not just the electricity or current. For me every actions, thoughts, emotions etc represents
Energy. We speak, write, read, listen all our actions are a form of energy exchange. `}</p>
    <p>{`From first law of thermodynamics we know energy can be neither created nor destroyed, it can be only converted to one or another form. Indeed this is one of the biggest secret know to humanity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      